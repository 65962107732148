html {
    scroll-behavior: smooth;
}

:focus {
    outline: none;
}

::-webkit-scrollbar {
    width: 0.8rem;
    height: 0.4rem;
}
::-webkit-scrollbar-track {
    background: var(--backgroundColor);
}
::-webkit-scrollbar-thumb {
    background: #7d7d7d90;
}
::-webkit-scrollbar-thumb:hover {
    background: #7d7d7d;
}

@media (pointer: none), (hover: none) {
    ::-webkit-scrollbar {
        width: 0;
        height: 0.2rem;
    }
}
