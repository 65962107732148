@font-face {
    font-family: "Hafs";
    src: local("Hafs"),
        url(../Fonts/a565b17e58e287300f1cd79645ae1770.woff) format("woff"),
        url(../Fonts/a565b17e58e287300f1cd79645ae1770.ttf) format("ttf"),
        url(../Fonts/a565b17e58e287300f1cd79645ae1770.woff2) format("woff2");
}
@font-face {
    font-family: "BYekan";
    src: local("BYekan"), url(../Fonts/BYekan.woff) format("woff"),
        url(../Fonts/BYekan.eot) format("eot"),
        url(../Fonts/BYekan.woff2) format("woff2");
}

html {
    font-size: 62.5%;
    font-family: "BYekan";
}

body {
    margin: 0;
    scroll-behavior: smooth;
    padding-bottom: env(safe-area-inset-bottom);
    background-color: #222222;
}

:-webkit-any-link {
    color: inherit;
    text-decoration: none;
}

@media screen and (max-width: 600px) {
    #botNavBar {
        padding-bottom: 1.6rem;
    }
}
